@.myspacestorSiteHandler = ->
    $(document).ready(->
        bindMyspacestorEvents()
    )
    rebindImageFavouriteEvents = ->
        window.renderProgressiveImages()
        window.lazyLoad?.update()
        #        bindProductDropdownEvents()
        bindImageFavouriteEvents()

    rebindProductFavouriteEvents = ->
        window.renderProgressiveImages()
        window.lazyLoad?.update()
        bindProductDropdownEvents()
        bindProductFavouriteEvents()

    bindImageFavouriteEvents = ->
        buildGrid()
        if Boolean($('#myFavouriteImages').length)
          buildFavourites()

        $('.grid-stack-item-content').mouseenter(() ->
            if window.innerWidth > window.gridBreakpoints.sm
                $(this).addClass('active')
        ).mouseleave(() ->
            if window.innerWidth > window.gridBreakpoints.sm
                 $(this).removeClass('active')
        )
        $('.grid-stack-item-content').click(() ->
            if window.innerWidth < window.gridBreakpoints.lg
                $(this).addClass('active')
        )
        $('.grid-stack__toggle-content').click(() ->
            if window.innerWidth < window.gridBreakpoints.lg
                $(this).parent('.grid-stack-item-content').toggleClass('active')
        )
        $('.box-grid__actions__button--view-all').click(() ->
            $(this).parent().siblings('.content-container').toggleClass('opened')
            if $(this).parent().siblings().hasClass('opened')
               $(this).text(gettext('view less'))
            else
               $(this).text(gettext('view all'))
        )
        $('.box-grid__content__item').mouseenter(() ->
            if window.innerWidth > window.gridBreakpoints.sm
                $(this).addClass('active')
        ).mouseleave(() ->
            if window.innerWidth > window.gridBreakpoints.sm
                $(this).removeClass('active')
        )
        $('.box-grid__content__item__toggle-content').on('click', ->
            if window.innerWidth < window.gridBreakpoints.sm
                $(this).parents('.box-grid__content__item').toggleClass('active')
        )
        $('.box-grid--toggle .box-grid__title').on('click', ->
            if window.innerWidth < window.gridBreakpoints.sm
                $(this).toggleClass('active').siblings('.box-grid__content').toggleClass('active')
        )
        $('.box-grid__content__item__content__list__item--remove').on('click', (e) ->
            type = $(this).data('type')
            objectId = $(this).data('object-id')
            $.ajax({
                type: 'POST'
                url: '/myspacestor/change-favourite/'
                data: {
                    'type': type,
                    'object_id': objectId,
                    'source': 'myfavourites-image',
                    'favourite': false
                }
                success: (data) ->
                    htmlContent = data.content
                    if $('#myFavouriteImages').find('.box-grid__content').hasClass('box-grid__content--mh')
                        $('#myFavouriteImages').html(htmlContent)
                    else
                        $('#myFavouriteImages').html(htmlContent)
                        $('#myFavouriteImages')
                            .find('.box-grid__content__content')
                            .removeClass('box-grid__content--mh')
                    rebindImageFavouriteEvents()
                error: (data) ->
                    console.log('failure ' + data)

                headers: {
                    'X-CSRFToken': getCookie('csrftoken')
                }
            })
        )

    bindProductFavouriteEvents = ->
        $('.product-list__boxes__box__features__item--remove').on('click', (e) ->
            type = $(this).data('type')
            objectId = $(this).data('object-id')
            $.ajax({
                type: 'POST'
                url: '/myspacestor/change-favourite/'
                data: {
                    'type': type,
                    'object_id': objectId,
                    'source': 'myfavourites-product',
                    'favourite': false
                }
                success: (data) ->
                    htmlContent = data.content
                    if $('#myFavouriteProducts').find('.myspacestor-product-list__content').hasClass('myspacestor-product-list__content--mh')
                        $('#myFavouriteProducts').html(htmlContent)
                    else
                        $('#myFavouriteProducts').html(htmlContent)
                        $('#myFavouriteProducts')
                            .find('.myspacestor-product-list__content')
                            .removeClass('myspacestor-product-list__content--mh')
                    rebindProductFavouriteEvents()
                error: (data) ->
                    console.log('failure ' + data)

                headers: {
                    'X-CSRFToken': getCookie('csrftoken')
                }
            })
        )

    bindMyspacestorEvents = ->
        bindImageFavouriteEvents()
        bindProductFavouriteEvents()

        $('.customizer-action-button').on('mouseover', ->
            $(this).parents('.customizer-action-buttons').find('.customizer-action-button.active').removeClass('active')
            $(this).addClass('active')
        )
        $('.customizer-action-button').on('mouseleave', ->
            $(this).parents('.customizer-action-buttons').find('.customizer-action-button.active').removeClass('active')
            $(this).parents('.customizer-action-buttons').find('.customizer-action-button--edit').addClass('active')
        )
        $('.mycustomizer-box').on('click', (e) ->
            box = $(this)
            target = $(e.target)

            if target.hasClass('board-item__content__more')
               target
                .siblings('.board-item__content__remove-confirm')
                .addClass('active')

            if target.hasClass('board-item__content__remove-confirm__content__cancel')
               target
                .parents('.board-item__content__remove-confirm')
                .removeClass('active')

            if target.hasClass('board-item__content__remove-confirm__content__remove')
                designId = box.data('design-id')
                $.ajax({
                    type: "POST"
                    url: '/myspacestor/remove-design/'
                    data: {
                        "designId": designId,
                    }
                    success: (data) =>
                        box.remove()
                    error: (data) =>
                       console.log('Error ' + data)

                    headers: {
                        "X-CSRFToken": getCookie("csrftoken")
                    }
                })
        )
        $('.select2-search').select2({
            width: '100%'
            placeholder: gettext('Choose Country')
            language: window.current_language || 'en'
        }).on('select2-loaded', (e) ->
            items = e.items.results
            if (items.length == 1)
                console.log($(this).val())
        ).on('select2:select', (e) ->
            $('.country-select2-label').addClass('active')
        )
        $('.toggle-sibling').on('click', ->
            $(this).toggleClass('active').siblings('.toggled-section').toggleClass('active')
        )
        $('.myspacestor-empty-section__title').on('click', ->
            if window.innerWidth < window.gridBreakpoints.sm
                $(this).toggleClass('active').siblings('.myspacestor-empty-section__box').toggleClass('active')
        )
        $('.myspacestor-product-list__title').on('click', ->
            if window.innerWidth < window.gridBreakpoints.sm
                $(this).toggleClass('active').siblings('.myspacestor-product-list__content').toggleClass('active')
        )
        $('.myspacetor-featured-products__content__image__action-wrapper__action, .box-grid__content__item__action-wrapper__action').on('click', ->
            type = $(this).data('type')
            objectId = $(this).data('object-id')
            element = $(this)
            $.ajax({
                type: 'POST'
                url: '/myspacestor/change-favourite/'
                data: {
                    'type': type,
                    'object_id': objectId,
                    'source': 'myfavourites-product',
                    'favourite': true
                }
                success: (data) ->
                    htmlContent = data.content
                    $('#myFavouriteProducts').html(htmlContent)
                    rebindProductFavouriteEvents()
                    element.addClass('active')
                    element.text(gettext('Added to My Favourites'))
                error: (data) ->
                    console.log('failure ' + data)

                headers: {
                    'X-CSRFToken': getCookie('csrftoken')
                }
            })
        )
    buildGrid = ->
        windowContainer = $('.box-grid__content')
        windowWidth = $('.box-grid__content').width()
        singleProjectBox = $('.box-grid__content__item')
        if window.innerWidth > window.gridBreakpoints.md
            bigBoxWidth = windowWidth / 2
            bigBoxHeight = bigBoxWidth * 0.75
            smallBoxWidth = windowWidth / 4
            smallBoxHeight = smallBoxWidth * 0.75
            singleProjectBox.each((index) ->
                if $(this).is(':first-child') or $(this).is(':nth-child(5n+1)')
                    $(this).css('width', bigBoxWidth)
                    $(this).css('height', bigBoxHeight)
                    $(this).addClass('big')
                else
                    $(this).css('width', smallBoxWidth)
                    $(this).css('height', smallBoxHeight)
                return
            )
        $('body').find('.fixImgWidth').each((index) ->
            $container = $(this).parent()
            containerWidth = $container.width()
            containerHeight = $container.height()
            widthImg = $(this).width()
            heightImg = $(this).height()
            imageRatio = widthImg / heightImg
            divRatio = containerWidth / containerHeight
            if imageRatio <= divRatio
                $(this).css({
                    width: '100%'
                    height: 'auto'
                })
            else
                $(this).css({
                    width: 'auto'
                    height: '100%'
                })
        )
    buildFavourites = ->

        fillMuuri = (data) ->
          elemData = _.map(data, ((el) ->
            temp = document.createElement('template')
            temp.innerHTML = el.trim()
            temp.content.firstChild
          ), this)
          favFinishesGrid.add elemData, index: 0
          favFinishesGrid.sort (left, right) ->
            leftVal = parseInt(left.getElement().getAttribute('data-id'))
            rightVal = parseInt(right.getElement().getAttribute('data-id'))
            leftVal - rightVal
          renderProgressiveImages true
          return
        # TODO: bind to existing element
        $('.finishes-grid__item--remove').on('click', (e) ->
            objectId = $(this).data('object-id')
            type = $(this).data('object-type')
            $.ajax({
                type: 'POST'
                url: '/myspacestor/change-favourite/'
                data: {
                    'type': type,
                    'object_id': objectId,
                    'source': 'myfavourites-product',
                    'favourite': false
                }
                success: (data) ->
                    # TODO: remove item from grid
                    console.log('success')
                error: (data) ->
                    console.log('failure ' + data)

                headers: {
                    'X-CSRFToken': getCookie('csrftoken')
                }
            })
        )

        serializeGridData = ->
          data = _.map($('.grid-stack > .grid-stack-item:visible'), ((el) ->
            el = $(el)
            node = el.data('_gridstack_node')
            if node
              return {
                objectId: el.data('object-id')
                likeType: el.data('like-type')
                x: node.x
                y: node.y
                width: node.width
                height: node.height
              }
            return
          ), this)
          $.ajax
            type: 'POST'
            url: '/myspacestor/grid-likes/'
            data:
              'object_type': 'images'
              'images': JSON.stringify(data, null, '    ')
            headers: 'X-CSRFToken': getCookie('csrftoken')
          return

        fillGridStack = (gridData) ->
          grid = $('.grid-stack').data('gridstack')
          grid.removeAll()
          items = GridStackUI.Utils.sort(gridData)
          _.each items, ((node, i) ->
            elem = $(node.content)
            largeTile = (i + 1) % 10 == 3 or (i + 1) % 10 == 7
            node_w = undefined
            node_h = undefined
            if !node.width
              node_w = if largeTile then 2 else 1
            else
              node_w = node.width
            if !node.height
              node_h = if largeTile then 2 else 1
            else
              node_h = node.height
            renderProgressiveImages true
            grid.addWidget elem, node.x, node.y, node_w, node_h
            toggler = elem.children().children('.box-grid__content__item__toggle-content')
            list = toggler.siblings('.box-grid__content__item__content__list')
            removeBtn = list.children('.box-grid__content__item__content__list__item--remove')
            elem.on('mouseover', ->
              $(this)
                .children()
                .children('.box-grid__content__item__toggle-content')
                .addClass('active')
            ).on('mouseleave', ->
              $(this)
                .children()
                .children('.box-grid__content__item__toggle-content')
                .removeClass('active')
                .siblings('.box-grid__content__item__content__list')
                .removeClass('active')
            )
            toggler.on('mouseover', ->
              if window.innerWidth > window.gridBreakpoints.avg
               $(this)
                 .siblings('.box-grid__content__item__content__list')
                 .addClass('active')
            )
            toggler.on('click', ->
              if window.innerWidth <= window.gridBreakpoints.avg
               $(this)
                 .siblings('.box-grid__content__item__content__list')
                 .toggleClass('active')
            )
            list.on('mouseleave', ->
              $(this).removeClass('active')
            )
            removeBtn.on('click', ->
              widget = $(this).closest('.grid-stack-item')
              type = widget.data('object-type')
              objectId = widget.data('object-id')
              $.ajax({
                    type: 'POST'
                    url: '/myspacestor/change-favourite/'
                    data: {
                        'type': type,
                        'object_id': objectId,
                        'source': 'myfavourites-product',
                        'favourite': false
                    }
                    success: (data) ->
                        grid.removeWidget widget
                    error: (data) ->
                        console.log('failure ' + data)

                    headers: {
                        'X-CSRFToken': getCookie('csrftoken')
                    }
                })
            )
            return
          ), this
          false

        do ->
          $('.grid-stack').gridstack({
            cellHeight: 180
            vertical_margin: 10
            acceptWidgets: '.grid-stack-item'
            animate: true
            width: 4
          }).on('change', (e, items) ->
            serializeGridData()
            return
          )
        favFinishesGrid = new Muuri('.finishes-grid',
            dragAxis: 'y'
            dragEnabled: true)
        $.ajax
            type: 'GET'
            url: '/myspacestor/grid-likes/'
            headers: 'X-CSRFToken': getCookie('csrftoken')
            success: (response) ->
              fillGridStack response['data']['images']
              fillMuuri response['data']['finishes']
              return
        favFinishesGrid.on 'dragReleaseEnd', (item) ->
          gridData = _.map(favFinishesGrid.getItems(), ((item, i) ->
            elem = item.getElement()
            if item
              return {
                objectId: elem.getAttribute('data-object-id')
                order: i
              }
            return
          ), this)
          $.ajax
            type: 'POST'
            url: '/myspacestor/grid-likes/'
            data:
              'object_type': 'finishes'
              'images': JSON.stringify(gridData, null, '    ')
            headers: 'X-CSRFToken': getCookie('csrftoken')
          return
        return

        $ ->
            options = alwaysShowResizeHandle: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
            $('.grid-stack').gridstack options
            return

        $('.grid-stack').addTouch();



